<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("schools.title") }}
          </template>
          <template v-slot:toolbar>
            <b-button v-b-modal.modal-1>{{ $t("common.new") }}</b-button>
          </template>
          <template v-slot:body>
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">{{ $t("schools.list.nameRu") }}</th>
                    <th class="text-left">{{ $t("schools.list.nameKg") }}</th>
                    <th class="text-left">{{ $t("schools.list.shortNameRu") }}</th>
                    <th class="text-left">{{ $t("schools.list.shortNameKg") }}</th>
                    <th class="text-left">{{ $t("schools.list.number") }}</th>
                    <th class="text-left">{{ $t("schools.list.siteKey") }}</th>
                    <th class="text-left">{{ $t("schools.list.region") }}</th>
                    <th class="text-left">{{ $t("schools.list.address") }}</th>
                    <th class="text-left">{{ $t("schools.list.contactPhone") }}</th>
                    <th class="text-left"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in schools" :key="item.id">
                    <td>{{ item.nameRu }}</td>
                    <td>{{ item.nameKg }}</td>
                    <td>{{ item.shortNameRu }}</td>
                    <td>{{ item.shortNameKg }}</td>
                    <td>{{ item.number }}</td>
                    <td>{{ item.siteKey }}</td>
                    <td>{{ item.region }}</td>
                    <td>{{ item.address }}</td>  
                    <td>{{ item.contactPhone }}</td>
                    <td>
                      <b-button @click="editSchool(item)" variant="primary">
                        {{ $t("common.edit") }}
                      </b-button>
                      <b-button @click="openTeachers(item)" variant="primary">
                        {{ $t("teachersList.title") }}
                      </b-button>
                      <b-button @click="openStudentGroups(item)" variant="primary">
                        {{ $t("studentGroups.title") }}
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <div>
              <b-modal id="modal-1" ref="school-modal">
                <template v-slot:modal-title>
                  {{ $t("common.new") }}
                </template>
                <b-form v-if="show">
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("schools.list.nameRu") }}
                    </template>
                    <b-form-input
                      v-model="form.nameRu"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("schools.list.nameKg") }}
                    </template>
                    <b-form-input
                      v-model="form.nameKg"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("schools.list.shortNameRu") }}
                    </template>
                    <b-form-input
                        v-model="form.shortNameRu"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("schools.list.shortNameKg") }}
                    </template>
                    <b-form-input
                        v-model="form.shortNameKg"
                    ></b-form-input>
                  </b-form-group>       
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("schools.list.number") }}
                    </template>
                    <b-form-input
                        v-model="form.number"
                    ></b-form-input>
                  </b-form-group>         
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("schools.list.siteKey") }}
                    </template>
                    <b-form-input
                        v-model="form.siteKey"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("schools.list.region") }}
                    </template>
                    <b-form-select
                        v-model="form.regionId"
                        :options="regions"
                    ></b-form-select>
                  </b-form-group>
                  <b-form-group id="input-group-3" label-for="input-3">
                    <template v-slot:label>
                      {{ $t("schools.list.address") }}
                    </template>
                    <b-form-input
                      v-model="form.address"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group id="input-group-4" label-for="input-4">
                    <template v-slot:label>
                      {{ $t("schools.list.contactPhone") }}
                    </template>
                    <b-form-input
                      v-model="form.contactPhone"
                    ></b-form-input>
                  </b-form-group>
                </b-form>
                <template v-slot:modal-footer>
                  <b-button type="submit" @click="onSubmit" variant="primary">{{
                    $t("common.save")
                  }}</b-button>
                  <b-button type="reset" @click="onReset" variant="danger">{{
                    $t("common.cancel")
                  }}</b-button>
                </template>
              </b-modal>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<style>
  td>button{
    margin-right: 10px;
  }
</style>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";

export default {
  name: "schools",
  components: {
    KTPortlet
  },
  data() {
    return {
      schools: null,
      dialog: true,
      regions:[],
      form: {
        nameRu: "",
        nameKg: "",
        address: "",
        contactPhone: "",
        regionId: ""
      },
      show: true
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      let $this = this;
      ApiService.postSecured("admin/save", this.form)
        .then(function() {
          $this.$refs["school-modal"].hide();
          $this.reloadData();
          $this.cleanModel();
        })
        .catch(({ response }) => {
          console.log(response);
          $this.reloadData();
        });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs["school-modal"].hide();
    },
    reloadData() {
      ApiService.querySecured("admin/schools")
        .then(({ data }) => {
          this.schools = data.list;
        })
        .catch(({ response }) => {
          console.log(response);
        });

      ApiService.querySecured("dictionaries/regions")
          .then(({ data }) => {
            this.regions = data.dictionary;
          })
          .catch(({ response }) => {
            console.log(response);
          });
    },
    cleanModel() {
      this.form = {};
    },
    editSchool(school) {
      this.form = school;
      this.$refs["school-modal"].show();
    },
    openTeachers(school) {
      this.$router.push({ name: "teachers", params: { id: school.id } });
    },
    openStudentGroups(school) {
      this.$router.push({ name: "studentGroups", params: { id: school.id } });
    }
  }
};
</script>
